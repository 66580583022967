import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import "../styles/portfolio.css";
import { useEffect, useState } from "react";
import { getCMSContent } from "../common/cms";

function Portfolio() {
  const [CONTENT, setContent] = useState({});
  useEffect(() => {
    const fetch = async () => {
      const response = await getCMSContent({name: 'content', cached: true});
      if (response) setContent(response);
    }
    fetch();
  },[]);

  const getSize = bytes => {
    if (!bytes || isNaN(bytes) || bytes < 1000000) return '';
    return `(${(bytes / 1048576).toFixed(1)} MB)`
  };

  return (<>
    <Header />
    <Helmet>
      <title>Portfolio | Kasha Design</title>
      <meta name="robots" content="noindex" data-react-helmet="true" />
    </Helmet>
		<div className="main-content portfolio">
      <div className="container">
        <h1>Portfolio</h1>
        <p>View a detailed collection of all our previous works and projects.</p>
        <div className="portfolio-links">
            <a href={CONTENT?.portfolio?.url} className="portfolio-link">
              <span className="label">Download</span>
              <span className="size">{getSize(CONTENT?.portfolio?.size)}</span>
              </a>
        </div>
      </div>
    </div>
    <Footer />
  </>);
};

export default Portfolio;